export const INITIAL = 'FETCH_STATE_INITIAL';
export const REQUEST = 'FETCH_STATE_REQUEST';
export const SUCCESS = 'FETCH_STATE_SUCCESS';
export const FAILURE = 'FETCH_STATE_FAILURE';

export default {
  INITIAL,
  REQUEST,
  SUCCESS,
  FAILURE,
};
