import ENV from 'constants/env';

const PLACES_URL = `https://maps.googleapis.com/maps/api/js?&key=${ENV.GOOGLE_MAPS_API_KEY}&channel=onixtest&libraries=places`;
const PLACES_ID = 'google-places-script';

function loadGooglePlaces() {
  if (!document.querySelector(`#${PLACES_ID}`)) {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', PLACES_ID);
    script.src = PLACES_URL;
    head.appendChild(script);
  }
}

export default loadGooglePlaces;
