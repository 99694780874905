import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MetricsPropTypes } from 'react-metrics';
import { useLocation } from 'react-router';
import clsx from 'clsx';
import { TextField, makeStyles, CircularProgress, FormHelperText } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import RexAPI from '@rex-change/rexlib/dist/RexAPI';
import debounce from 'lodash/debounce';
import { INITIAL, REQUEST, SUCCESS, FAILURE } from 'constants/fetchStates';
import { REX_SVC_CLIENT_ADDRESS_SUGGEST_HOST } from 'constants/api';

const THROTTLE_DELAY_MS = 150;

const addressSearchApi = new RexAPI({
  baseURL: REX_SVC_CLIENT_ADDRESS_SUGGEST_HOST,
});

async function fetchUnits(query, value) {
  const response = await addressSearchApi.get(`/smarty-address?search=${query}&selected=${value}`);
  return response?.data;
}

const useStyles = makeStyles((theme) => ({
  autoCompleteRoot: (props) => ({
    display: props.horizontalAtMedium ? 'grid' : 'inherit',
    alignSelf: props.horizontalAtMedium ? 'center' : 'inherit',
    flexGrow: 1,
  }),
  input: {
    background: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    minWidth: theme.spacing(25),

    // Using mui class directly here since AutoComplete does not
    // expose the rule of the right specificity
    '&[class*="MuiOutlinedInput-root"]': {
      padding: theme.spacing(0.5, 1),
    },
  },
}));

function SuggestAddress(
  { horizontalAtMedium, placeholder, error, onSelectLocation, wrapperStyles },
  { metrics },
) {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchState, setSearchState] = useState(INITIAL);
  const [query, setQuery] = useState('');
  const [hasTyped, setHasTyped] = useState(false);

  const { pathname } = useLocation();
  const trackingPath = pathname.substring(1) || 'home-page';

  const classes = useStyles({ horizontalAtMedium });

  const debouncedFetchLocations = useCallback(
    debounce(
      async (newQuery, onFinish) => {
        const response = await addressSearchApi.get(`/smarty-address?search=${newQuery}`);
        const newOptions = response?.data;

        // only display first five results
        const truncatedOptions = newOptions?.slice(0, 5);
        onFinish(truncatedOptions);
      },
      THROTTLE_DELAY_MS,
      { trailing: true },
    ),
    [],
  );

  useEffect(() => {
    let isActive = true;
    setSearchState(REQUEST);

    async function fetchData() {
      try {
        debouncedFetchLocations(query, (locations) => {
          if (isActive) {
            setOptions(locations ?? []);
            setSearchState(SUCCESS);
          }
        });
      } catch (err) {
        if (isActive) {
          setOptions([]);
          setSearchState(FAILURE);
        }
      }
    }

    if (!query) {
      setOptions([]);
      setSearchState(SUCCESS);
    } else {
      fetchData();
    }

    return () => {
      isActive = false;
    };
  }, [query, debouncedFetchLocations]);

  const onTypeAddress = (value) => {
    if (!hasTyped) {
      metrics.track('change', {
        category: `${trackingPath}-suggest-address`,
        label: 'input-started-typing',
      });
      setHasTyped(true);
    }
    setQuery(value);
  };

  const onSelectAddress = (value) => {
    if (value.includes('entries')) {
      fetchUnits(query, value).then((units) => setOptions(units));
      return;
    }
    onSelectLocation(value);
    setIsOpen(false);
  };

  return (
    <div className={clsx(classes.autoCompleteRoot, wrapperStyles)}>
      <Autocomplete
        autoComplete
        data-rex-id="suggest-address-typeahead"
        disableCloseOnSelect
        includeInputInList
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        onChange={(event, value) => onSelectAddress(value)}
        onInputChange={(event, value) => setQuery(value)}
        options={options}
        noOptionsText={!query ? 'Please begin typing...' : 'No results found.'}
        filterOptions={(x) => x}
        loading={searchState === REQUEST}
        classes={{ root: clsx(classes.autoCompleteRoot, wrapperStyles) }}
        renderInput={(params) => (
          <TextField
            /* Disabled as this is a pass through for input props */
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...params}
            fullWidth
            variant="outlined"
            onChange={({ target: { value } }) => onTypeAddress(value)}
            onClick={() => {
              metrics.track('click', {
                category: `${trackingPath}-suggest-address`,
                label: 'suggest-address-input',
              });
            }}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              classes: { root: classes.input },
              color: 'secondary',
              endAdornment: (
                <>
                  {searchState === REQUEST ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            InputLabelProps={{
              ...params.InputLabelProps,
              classes: { root: classes.label },
              color: 'secondary',
            }}
          />
        )}
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </div>
  );
}

SuggestAddress.propTypes = {
  horizontalAtMedium: PropTypes.bool,
  placeholder: PropTypes.string,
  onSelectLocation: PropTypes.func,
  error: PropTypes.string,
  wrapperStyles: PropTypes.string,
};

SuggestAddress.defaultProps = {
  horizontalAtMedium: false,
  placeholder: 'Enter an address, city, zip code, or neighborhood',
  onSelectLocation: () => {},
  error: '',
  wrapperStyles: '',
};

SuggestAddress.contextTypes = {
  metrics: MetricsPropTypes.metrics,
};

export default SuggestAddress;
