import React from 'react';

function Logo() {
  return (
    <svg data-name="Logo" viewBox="0 0 273.97 93.57" xmlns="http://www.w3.org/2000/svg">
      <title>REX logo</title>
      <path
        d="m57.09 54.15a29.16 29.16 0 0 0 12.65-24.36 29.79 29.79 0 0 0 -29.74-29.79h-33.25c-6.35 0-6.75 5.44-6.75 5.44v88h17.28v-33.86h22.89l13.61 33.87h18.75zm-20.41-11.15h-19.36v-26.42h19.36c7.59 0 13.76 5.74 13.76 13.33s-6.17 13.09-13.76 13.09zm72.95-26.24h43.59v-16.76h-59.39a5.76 5.76 0 0 0 -5.16 5.2v83.15c0 2.57 3.27 5.2 5.62 5.2h58.93v-16.84h-43.59q-4.28 0-4.28-4.32v-17.69h37.85v-16.76h-37.85v-16.86q0-4.32 4.28-4.32zm-21 58.24v-55.62zm100.65-75h-12.22a5 5 0 0 0 -5 5 4.91 4.91 0 0 0 .63 2.44l23.31 39.35-23.3 39.28a4.91 4.91 0 0 0 -.63 2.44 5 5 0 0 0 5 5h12.22l27.83-46.77zm67.43 93.56h12.29a5 5 0 0 0 5-5 4.91 4.91 0 0 0 -.63-2.44l-23.3-39.28 23.27-39.29a4.92 4.92 0 0 0 .66-2.44 5 5 0 0 0 -5-5h-12.29l-27.83 46.77z"
        fill="#FF685F"
      />
    </svg>
  );
}

export default Logo;
