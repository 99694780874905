import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Formik, Form, Field } from 'formik';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { object as yupObject, string as yupString } from 'yup';
import RexAPI from '@rex-change/rexlib/dist/RexAPI';
import { REX_SVC_REX_APP_HOST, REX_SVC_CLIENT_SHERPA_HOST } from 'constants/api';
import { redirect } from 'helpers/redirect';
import logger from 'utils/logger';
import SuggestAddress from 'components/SuggestAddress';
import FlowButtonContainer from '../FlowButtonContainer';
import SherpaPrimaryButton from '../SherpaPrimaryButton';

const useStyles = makeStyles((theme) => ({
  root: ({ isFullWidth }) =>
    isFullWidth
      ? {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }
      : {},
  buttonContainer: {
    width: ({ isRow }) => (isRow ? 'fit-content' : 'auto'),
    margin: 0,
  },
  submitButton: {
    width: ({ isRow }) => (isRow ? 'auto' : '100%'),
    margin: ({ isFullWidth }) => (isFullWidth ? theme.spacing(1, 0, 0, 0) : 0),
  },
}));

const sherpaClient = new RexAPI({
  baseURL: REX_SVC_CLIENT_SHERPA_HOST,
});

const rexAppClient = new RexAPI({
  baseURL: REX_SVC_REX_APP_HOST,
});

function AddressSearch({
  horizontalAtMedium,
  onSubmit: passedOnSubmit,
  submitButtonCopy,
  isFullWidth,
  isRow,
  trackingLabel,
  placeholder,
  containerStyles,
  suggestWrapStyles,
  buttonColor,
  buttonStyles,
  buttonContainerStyles,
}) {
  const classes = useStyles({ isFullWidth, isRow });
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async ({ address }) => {
    setIsSubmitting(true);
    const uriAddress = encodeURIComponent(address);

    sherpaClient
      .get(`/listing/property/coordinates?address=${uriAddress}`)
      .then((response) => {
        const coordinates = response.data;
        const { latitude, longitude } = coordinates;

        rexAppClient
          .get('/api/geo/serviceArea/point', { lat: latitude, lon: longitude })
          .then(() => passedOnSubmit(address, history, latitude, longitude))
          .catch((err) => {
            if (err?.response?.status === 404) {
              redirect('/#new-area-signup', history);
              return;
            }
            logger.error('Error fetching service area for address.');
            throw err;
          });
      })
      .catch(() => {
        logger.error('Error fetching coordinates for address.');
        passedOnSubmit(address, history);
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Formik
      validationSchema={AddressSearch.formSchema}
      initialValues={AddressSearch.initialValues}
      onSubmit={onSubmit}
    >
      <Form className={clsx(classes.root, containerStyles)}>
        <Field name="address">
          {({ field, form: { touched, errors, setFieldValue } }) => (
            <SuggestAddress
              horizontalAtMedium={horizontalAtMedium}
              placeholder={placeholder}
              error={touched[field.name] && errors[field.name]}
              onSelectLocation={(address) => setFieldValue(field.name, address)}
              wrapperStyles={suggestWrapStyles}
            />
          )}
        </Field>
        <FlowButtonContainer className={clsx(classes.buttonContainer, buttonContainerStyles)}>
          <SherpaPrimaryButton
            color={buttonColor}
            data-rex-id="sherpa-address-submit-btn"
            type="submit"
            trackingCategory="submit-address"
            trackingLabel={trackingLabel}
            className={clsx(classes.submitButton, buttonStyles)}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress /> : submitButtonCopy}
          </SherpaPrimaryButton>
        </FlowButtonContainer>
      </Form>
    </Formik>
  );
}

AddressSearch.propTypes = {
  horizontalAtMedium: PropTypes.bool,
  onSubmit: PropTypes.func,
  submitButtonCopy: PropTypes.string,
  trackingLabel: PropTypes.string,
  isFullWidth: PropTypes.bool,
  isRow: PropTypes.bool,
  placeholder: PropTypes.string,
  containerStyles: PropTypes.string,
  suggestWrapStyles: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonStyles: PropTypes.string,
  buttonContainerStyles: PropTypes.string,
};

AddressSearch.defaultProps = {
  horizontalAtMedium: false,
  onSubmit: (address, history, latitude, longitude) => {
    const uriAddress = encodeURIComponent(address);
    history.push(`/listingAgreement/intro?address=${uriAddress}&lat=${latitude}&lon=${longitude}`);
  },
  submitButtonCopy: 'Price My Home',
  trackingLabel: undefined,
  isFullWidth: false,
  isRow: false,
  placeholder: 'Enter Your Address',
  containerStyles: '',
  suggestWrapStyles: '',
  buttonColor: 'primary',
  buttonStyles: '',
  buttonContainerStyles: '',
};

AddressSearch.formSchema = yupObject().shape({
  address: yupString().required('Please select an address from the menu.').nullable(),
});

AddressSearch.initialValues = {
  address: null,
};

export default AddressSearch;
