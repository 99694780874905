import React from 'react';
import { PropTypes } from 'prop-types';
import { Box, Container, makeStyles, useTheme } from '@material-ui/core';
import { RexTypography } from '@rex-change/brix';
import Carousel from 'components/Carousel';
import TestimonialCard from './TestimonialCard';

const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: theme.spacing(6),
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(10),
    },
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  header: {
    marginBottom: theme.spacing(4),
  },
  carouselWrapStyles: {
    margin: 'auto',
    maxWidth: theme.spacing(132.25),
    [theme.breakpoints.up('xl')]: {
      maxWidth: theme.spacing(138.5),
    },
  },
  navStyles: {
    paddingTop: 0,
    [theme.breakpoints.up('lg')]: {
      paddingBottom: theme.spacing(9),
    },
  },
  navDotStyles: {
    borderRadius: '50%',
  },
  slide: {
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
      width: 'auto',
    },
  },
  uppercaseTitleText: {
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.h3.fontSize,
    },
  },
}));

export function TestimonialCarousel({ testimonials, title, capitalizedTitle }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <section className={classes.section} data-testid="testimonial-carousel">
      <Container className={classes.header}>
        {capitalizedTitle && (
          <RexTypography
            align="center"
            className={classes.uppercaseTitleText}
            component="h2"
            data-test-id="testimonial-carousel-cap-title"
            variant="h6"
          >
            {capitalizedTitle}
          </RexTypography>
        )}
        {title && (
          <RexTypography
            align="center"
            className={classes.boldText}
            component="h2"
            data-test-id="testimonial-carousel-title"
            variant="h6"
          >
            {title}
          </RexTypography>
        )}
      </Container>
      <Carousel
        loop
        slidesPerView={1}
        navStyles={classes.navStyles}
        navDotStyles={classes.navDotStyles}
        activeNavDotColor={theme.palette.primary.main}
        baseNavDotColor={theme.palette.grey[400]}
        carouselWrapStyles={classes.carouselWrapStyles}
        breakpoints={{
          960: {
            slidesPerView: 2.75,
          },
          1280: {
            slidesPerView: 3,
          },
          1920: {
            slidesPerView: 3,
            spaceBetween: 14,
          },
        }}
      >
        {testimonials.map(({ name, tagline, image, description }) => (
          <Box className={classes.slide} key={name}>
            <TestimonialCard imgSrc={image} quote={description} name={name} tagline={tagline} />
          </Box>
        ))}
      </Carousel>
    </section>
  );
}

TestimonialCarousel.propTypes = {
  capitalizedTitle: PropTypes.string,
  title: PropTypes.string,
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      tagline: PropTypes.string,
      image: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
};

TestimonialCarousel.defaultProps = {
  capitalizedTitle: '',
  title: 'What People Are Saying',
  testimonials: [],
};

export default TestimonialCarousel;
