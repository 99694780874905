import React from 'react';
import PropTypes from 'prop-types';

function Copyright({ className: passedClassNames }) {
  const thisYear = new Date().getFullYear();

  return <span className={`rex-copyright ${passedClassNames}`}>{`© ${thisYear}`}</span>;
}

Copyright.propTypes = {
  className: PropTypes.string,
};

Copyright.defaultProps = {
  className: '',
};

export default Copyright;
