import React from 'react';
import clsx from 'clsx';
import { PillButton, RexTypography } from '@rex-change/brix';
import { Container, IconButton, makeStyles } from '@material-ui/core';
import { EMAIL_ADDRESS, PHONE_NUMBER } from 'constants/contact';
import formatPhone from '@rex-change/rexlib/dist/formatPhone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhoneAlt } from '@fortawesome/pro-light-svg-icons';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingBottom: theme.spacing(12),
    background: `linear-gradient(to bottom, transparent, transparent ${theme.spacing(15)}px, ${
      theme.palette.grey[800]
    } ${theme.spacing(15)}px, ${theme.palette.grey[800]})`,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row-reverse',
    },
  },
  image: {
    alignSelf: 'center',
    [theme.breakpoints.up('md')]: {
      alignSelf: 'flex-start',
    },
  },
  ctaButton: {
    color: 'inherit',
    borderColor: 'inherit',
    borderStyle: 'solid',
    borderWidth: 1,
    marginBottom: theme.spacing(1),
  },
  text: {
    color: 'inherit',
  },
  copyContainer: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(23),
    },
  },
  contactContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
  contactItem: {
    textAlign: 'center',
    marginRight: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2.5),
  },
  description: {
    marginBottom: theme.spacing(4.5),
  },
  miniCallCta: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  fullCallCta: {
    display: 'none',
    padding: theme.spacing(1.5, 2),
    height: 'auto',
    [theme.breakpoints.up('md')]: {
      display: 'inline-flex',
    },
  },
}));

function ContactUs() {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Container className={classes.container}>
        <img
          className={classes.image}
          src="https://s3.amazonaws.com/org-rex-media/assets/images/newAgent.png"
          alt=""
        />
        <div className={classes.copyContainer}>
          <RexTypography
            className={clsx(classes.text, classes.title)}
            variant="subtitle2"
            data-test-id="title"
          >
            Expert, fully-licensed agents
          </RexTypography>
          <RexTypography
            className={clsx(classes.text, classes.description)}
            variant="body2"
            data-test-id="description"
          >
            Our REX agents will guide you through the entire process from list or offer to closing
            on your home.
          </RexTypography>
          <div className={classes.contactContainer}>
            <div className={classes.contactItem} data-test-id="phoneButton">
              <IconButton
                className={clsx(classes.ctaButton, classes.miniCallCta)}
                href={`tel:${PHONE_NUMBER}`}
                variant="outlined"
              >
                <FontAwesomeIcon icon={faPhoneAlt} />
              </IconButton>
              <PillButton
                className={clsx(classes.ctaButton, classes.fullCallCta)}
                href={`tel:${PHONE_NUMBER}`}
                variant="outlined"
                startIcon={<FontAwesomeIcon icon={faPhoneAlt} />}
              >
                {formatPhone(PHONE_NUMBER)}
              </PillButton>
              <RexTypography className={classes.text} variant="body2">
                Call
              </RexTypography>
            </div>
            <div className={classes.contactItem} data-test-id="emailButton">
              <IconButton
                className={classes.ctaButton}
                href={`mailto:${EMAIL_ADDRESS}`}
                variant="outlined"
              >
                <FontAwesomeIcon icon={faEnvelope} />
              </IconButton>
              <RexTypography className={classes.text} variant="body2">
                Email
              </RexTypography>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ContactUs;
