import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, makeStyles } from '@material-ui/core';
import { MetricsElement } from 'react-metrics';
import { Link } from 'react-router-dom';
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import {
  faInstagram,
  faFacebookF,
  faTwitter,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';
import { PHONE_NUMBER, EMAIL_ADDRESS } from 'constants/contact';
import formatPhone from '@rex-change/rexlib/dist/formatPhone';
import Logo from 'components/Logo';
import EqualHousingLogo from 'img/svg/logo-equal-housing.svg';
import Copyright from 'components/Copyright';
import { asAbbreviationString } from 'utils/supportedStatesCopy';
import { GlobalDataContext } from 'contexts/GlobalDataContext/GlobalDataContext';
import './Footer.scss';

const useStyles = makeStyles((theme) => ({
  contentLg: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1.5, 6, 0),
    },
  },
  contactInfo: {
    display: 'flex',
    flexBasis: '100%',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
  },
  contactInfoLg: {
    [theme.breakpoints.up('md')]: {
      borderRight: `1px solid ${theme.palette.grey[400]}`,
      flexBasis: '33%',
      paddingRight: theme.spacing(4),
    },
  },
  navGroups: {
    display: 'flex',
    flexBasis: '100%',
    flexWrap: 'wrap',
  },
  navGroupsLg: {
    [theme.breakpoints.up('md')]: {
      flexBasis: '66%',
      padding: theme.spacing(1, 0, 1, 6),
    },
  },
  storeGroup: {
    justifyContent: 'center',
  },
}));

export function Footer({
  className: passedClassNames,
  phoneNumber,
  email,
  additionalDisclaimers,
  isNarrow,
  isFaqHidden,
}) {
  const classes = useStyles();
  return (
    <footer className={`rex-footer ${passedClassNames}`} data-test-id="footer" data-testid="footer">
      {!isFaqHidden && (
        <section className="rex-footer__question-ribbon">
          <div className="rex-footer__question-ribbon__item">
            <h3 className="rex-footer__question-ribbon__heading">Basic questions?</h3>
            <MetricsElement>
              <Link
                className="rex-footer__question-ribbon__cta"
                to="/faq"
                data-metrics-event-name="click"
                data-metrics-category="footer-cta"
                data-metrics-label="visit-faq"
                data-test-id="footer-visit-faq"
              >
                Visit our FAQs
              </Link>
            </MetricsElement>
          </div>
          <div className="rex-footer__question-ribbon__item">
            <h3 className="rex-footer__question-ribbon__heading">Not so basic questions?</h3>
            <MetricsElement>
              <a
                className="rex-footer__question-ribbon__cta"
                href="mailto:hello@rexhomes.com?subject=Question about Selling with REX"
                data-metrics-event-name="click"
                data-metrics-category="footer-cta"
                data-metrics-label="lets-keep-in-touch"
                data-test-id="footer-email-us"
              >
                Email us
              </a>
            </MetricsElement>
          </div>
        </section>
      )}
      <Box className={clsx(!isNarrow && classes.contentLg, 'rex-footer__content')}>
        <ul
          className={clsx(
            !isNarrow && classes.contactInfoLg,
            classes.contactInfo,
            'rex-footer__link-group',
          )}
        >
          <li className="rex-footer__item rex-footer__rex-logo">
            <Link className="rex-footer__link rex-footer__link--footer-logo" to="/">
              <Logo />
            </Link>
          </li>
          <li className="rex-footer__item">
            <MetricsElement>
              <a
                className="rex-footer__link"
                href={`tel:${phoneNumber}`}
                data-metrics-event-name="click"
                data-metrics-category="global-footer"
                data-metrics-label="call"
              >
                {formatPhone(phoneNumber)}
              </a>
            </MetricsElement>
          </li>
          <li className="rex-footer__item">
            <MetricsElement>
              <a
                className="rex-footer__link"
                href="mailto:hello@rexhomes.com"
                data-metrics-event-name="click"
                data-metrics-category="global-nav"
                data-metrics-label="email"
              >
                {email}
              </a>
            </MetricsElement>
          </li>
          <li className="rex-footer__item">
            <MetricsElement>
              <Link
                className="rex-footer__link rex-footer__link--secondary-cta"
                to="/careers"
                data-metrics-event-name="click"
                data-metrics-category="global-nav"
                data-metrics-label="careers"
                data-test-id="footer-hiring"
              >
                We're Hiring
              </Link>
            </MetricsElement>
          </li>
          <li
            className={clsx(
              isNarrow && classes.storeGroup,
              'rex-footer__item rex-footer__store-group',
            )}
          >
            <MetricsElement>
              <a
                className="rex-footer__store-group--link"
                href="https://apps.apple.com/us/app/rex-real-estate/id1450443888"
                data-metrics-event-name="click"
                data-metrics-category="global-nav"
                data-metrics-label="app"
                data-test-id="footer-app-store"
              >
                <LazyLoadImage
                  className="rex-footer__store-group--image"
                  src="/assets/images/icons/app-store.svg"
                  alt="REX App is on the App Store"
                />
              </a>
            </MetricsElement>
            <MetricsElement>
              <a
                className="rex-footer__store-group--link"
                href="https://play.google.com/store/apps/details?id=com.rexapp"
                data-metrics-event-name="click"
                data-metrics-category="global-nav"
                data-metrics-label="app"
                data-test-id="footer-google-play"
              >
                <LazyLoadImage
                  className="rex-footer__store-group--image"
                  src="/assets/images/icons/google-play-badge.svg"
                  alt="REX App is on Google Play"
                />
              </a>
            </MetricsElement>
          </li>
        </ul>
        <div className={clsx(!isNarrow && classes.navGroupsLg, classes.navGroups)}>
          <ul className="rex-footer__navigation-group rex-footer__link-group">
            <li className="rex-footer__navigation-group__title">Company</li>
            <li>
              <MetricsElement>
                <Link
                  className="rex-footer__link"
                  to="/about"
                  data-metrics-event-name="click"
                  data-metrics-category="footer"
                  data-metrics-label="about-rex"
                  data-test-id="footer-link-about-rex"
                >
                  About REX
                </Link>
              </MetricsElement>
            </li>
            <li>
              <MetricsElement>
                <Link
                  className="rex-footer__link"
                  to="/contact"
                  data-metrics-event-name="click"
                  data-metrics-category="footer"
                  data-metrics-label="contact"
                  data-test-id="footer-link-contact"
                >
                  Contact
                </Link>
              </MetricsElement>
            </li>
            <li>
              <MetricsElement>
                <a
                  className="rex-footer__link"
                  href="https://marketing.rexhomes.com/index.php/4623-2/"
                  data-metrics-event-name="click"
                  data-metrics-category="footer"
                  data-metrics-label="press"
                  data-test-id="footer-link-press"
                >
                  Press
                </a>
              </MetricsElement>
            </li>
            <li>
              <MetricsElement>
                <Link
                  className="rex-footer__link"
                  to="/partners"
                  data-metrics-event-name="click"
                  data-metrics-category="footer"
                  data-metrics-label="partners"
                  data-test-id="footer-link-partners"
                >
                  Partners
                </Link>
              </MetricsElement>
            </li>
            <li>
              <MetricsElement>
                <a
                  className="rex-footer__link"
                  href="/testimonials"
                  data-metrics-event-name="click"
                  data-metrics-category="footer"
                  data-metrics-label="success-stories"
                  data-test-id="footer-link-success-stories"
                >
                  Success Stories
                </a>
              </MetricsElement>
            </li>
            <li>
              <MetricsElement>
                <Link
                  className="rex-footer__link"
                  to="/rex-social-mission"
                  data-metrics-event-name="click"
                  data-metrics-category="footer"
                  data-metrics-label="rex-social-mission"
                  data-test-id="footer-link-social-mission"
                >
                  Social Mission
                </Link>
              </MetricsElement>
            </li>
            <li>
              <MetricsElement>
                <Link
                  className="rex-footer__link"
                  to="/careers"
                  data-metrics-event-name="click"
                  data-metrics-category="footer"
                  data-metrics-label="careers"
                  data-test-id="footer-link-careers"
                >
                  Careers
                </Link>
              </MetricsElement>
            </li>
            <li>
              <MetricsElement>
                <a
                  className="rex-footer__link"
                  href="https://blog.rexhomes.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-metrics-event-name="click"
                  data-metrics-category="footer"
                  data-metrics-label="blog"
                  data-test-id="footer-link-blog"
                >
                  Blog
                </a>
              </MetricsElement>
            </li>
            <li>
              <MetricsElement>
                <a
                  className="rex-footer__link"
                  href="https://shop.spreadshirt.com/merchandise-r-e-x/all"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-metrics-event-name="click"
                  data-metrics-category="footer"
                  data-metrics-label="buy-rex-gear"
                  data-test-id="footer-link-buy-rex-gear"
                >
                  Buy REX Gear
                </a>
              </MetricsElement>
            </li>
            <li>
              <MetricsElement>
                <a
                  className="rex-footer__link"
                  href="/rextionary101"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-metrics-event-name="click"
                  data-metrics-category="footer"
                  data-metrics-label="rextionary-101"
                  data-test-id="footer-link-rextionary-101"
                >
                  REXtionary101
                </a>
              </MetricsElement>
            </li>
          </ul>

          <ul className="rex-footer__navigation-group rex-footer__link-group">
            <li className="rex-footer__navigation-group__title">For Sellers</li>
            <li>
              <MetricsElement>
                <Link
                  className="rex-footer__link"
                  to="/sell-with-rex"
                  data-metrics-event-name="click"
                  data-metrics-category="footer"
                  data-metrics-label="selling-with-rex"
                  data-test-id="footer-link-selling"
                >
                  Selling With REX
                </Link>
              </MetricsElement>
            </li>
            <li>
              <MetricsElement>
                <Link
                  className="rex-footer__link"
                  to="/faq/seller"
                  data-metrics-event-name="click"
                  data-metrics-category="footer"
                  data-metrics-label="seller-faq"
                  data-test-id="footer-link-seller-faq"
                >
                  Seller FAQ
                </Link>
              </MetricsElement>
            </li>
          </ul>

          <ul className="rex-footer__navigation-group rex-footer__link-group">
            <li className="rex-footer__navigation-group__title">For Buyers</li>
            <li>
              <MetricsElement>
                <a
                  className="rex-footer__link"
                  href="/home-loans"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-metrics-event-name="click"
                  data-metrics-category="footer"
                  data-metrics-label="buyer-mortgage"
                  data-test-id="footer-link-mortgages"
                >
                  Mortgages
                </a>
              </MetricsElement>
            </li>
            <li>
              <MetricsElement>
                <a
                  className="rex-footer__link"
                  href="/service/insurance"
                  target="_blank"
                  data-metrics-event-name="click"
                  data-metrics-category="footer"
                  data-metrics-label="insurance"
                  data-test-id="footer-link-insurance"
                >
                  Home Insurance
                </a>
              </MetricsElement>
            </li>
            <li>
              <MetricsElement>
                <Link
                  className="rex-footer__link"
                  to="/faq/buyer"
                  data-metrics-event-name="click"
                  data-metrics-category="footer"
                  data-metrics-label="buyer-faq"
                  data-test-id="footer-link-buyer-faq"
                >
                  Buyer FAQ
                </Link>
              </MetricsElement>
            </li>
          </ul>
        </div>
        <div className="rex-footer__social-ribbon">
          <ul className="rex-footer__social-ribbon__links rex-footer__link-group">
            <li className="rex-footer__social-ribbon__link-container">
              <MetricsElement>
                <a
                  className="rex-footer__link rex-footer__link--logo"
                  href="https://instagram.com/rex_change/"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-metrics-event-name="click"
                  data-metrics-category="footer-social-click"
                  data-metrics-label="instagram"
                  data-test-id="footer-social-media-instagram"
                >
                  <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faCircle} />
                    <FontAwesomeIcon icon={faInstagram} inverse transform="shrink-7" />
                  </span>
                </a>
              </MetricsElement>
            </li>
            <li className="rex-footer__social-ribbon__link-container">
              <MetricsElement>
                <a
                  className="rex-footer__link rex-footer__link--logo"
                  href="https://www.facebook.com/REXRealEstateExchange"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-metrics-event-name="click"
                  data-metrics-category="footer-social-click"
                  data-metrics-label="facebook"
                  data-test-id="footer-social-media-facebook"
                >
                  <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faCircle} />
                    <FontAwesomeIcon icon={faFacebookF} inverse transform="shrink-7" />
                  </span>
                </a>
              </MetricsElement>
            </li>
            <li className="rex-footer__social-ribbon__link-container">
              <MetricsElement>
                <a
                  className="rex-footer__link rex-footer__link--logo"
                  href="https://twitter.com/rex_change"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-metrics-event-name="click"
                  data-metrics-category="footer-social-click"
                  data-metrics-label="twitter"
                  data-test-id="footer-social-media-twitter"
                >
                  <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faCircle} />
                    <FontAwesomeIcon icon={faTwitter} inverse transform="shrink-7" />
                  </span>
                </a>
              </MetricsElement>
            </li>
            <li className="rex-footer__social-ribbon__link-container">
              <MetricsElement>
                <a
                  className="rex-footer__link rex-footer__link--logo"
                  href="https://www.linkedin.com/company/rex---real-estate-exchange"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-metrics-event-name="click"
                  data-metrics-category="footer-social-click"
                  data-metrics-label="linkedin"
                  data-test-id="footer-social-media-linkedin"
                >
                  <span className="fa-layers fa-fw">
                    <FontAwesomeIcon icon={faCircle} />
                    <FontAwesomeIcon icon={faLinkedinIn} inverse transform="shrink-7" />
                  </span>
                </a>
              </MetricsElement>
            </li>
          </ul>
          <div className="rex-footer__social-ribbon__background-line" />
        </div>
        <div className="rex-footer__company-info">
          <div className="rex-footer__company-info__dre">DRE# 01976010</div>
          <div className="rex-footer__company-info__link-group">
            <a
              className="rex-footer__inline-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://s3.amazonaws.com/org-rex-media/assets/other/TREC-Information-About-Brokerage-Services-2021-Mar-22.pdf"
              data-test-id="footer-link-trec-info"
            >
              TREC Info About Brokerage
            </a>
            <a
              className="rex-footer__inline-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.trec.texas.gov/sites/default/files/pdf-forms/CN%201-2.pdf"
              data-test-id="footer-link-trec-consumer"
            >
              TREC Consumer Protection Notice
            </a>
          </div>
          <div>
            <a
              className="rex-footer__inline-link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.dos.ny.gov/licensing/docs/FairHousingNotice_new.pdf"
              data-test-id="footer-link-ny-anti-discrimination"
            >
              NY Housing and Anti-Discrimination Disclosure
            </a>
          </div>
          <div>
            REX - Real Estate Exchange, Inc. is a licensed real estate broker in{' '}
            {asAbbreviationString(false)} and abides by Equal Housing Opportunity laws.
          </div>
          <div className="rex-footer__company-info__link-group">
            <span>
              <Copyright /> REX
            </span>
            <MetricsElement>
              <a
                href="/terms-and-privacy/terms"
                className="rex-footer__inline-link"
                data-metrics-event-name="click"
                data-metrics-category="footer-privacy"
                data-metrics-label="footer-privacy"
                data-test-id="footer-link-terms-privacy"
              >
                Terms & Privacy
              </a>
            </MetricsElement>
            <MetricsElement>
              <a
                href="/contact"
                className="rex-footer__inline-link"
                data-metrics-event-name="click"
                data-metrics-category="footer-contact"
                data-metrics-label="footer-contact"
                data-test-id="footer-link-contact_2"
              >
                Contact
              </a>
            </MetricsElement>
            <span>REX Home Loans, LLC NMLS ID 1698411</span>
            <MetricsElement>
              <div>
                <a
                  href="https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1698411"
                  className="rex-footer__inline-link"
                  data-metrics-event-name="click"
                  data-metrics-category="footer-consumer-access-page"
                  data-metrics-label="footer-consumer-access-page"
                  data-test-id="footer-link-consumer-access-page"
                >
                  NMLS Consumer Access Page
                </a>
                <span> REX Home Loans, LLC</span>
              </div>
            </MetricsElement>
            <MetricsElement>
              <a
                href="/terms-and-privacy/consumer-concerns"
                className="rex-footer__inline-link"
                data-metrics-event-name="click"
                data-metrics-category="footer-disclosures-licenses"
                data-metrics-label="footer-disclosures-licenses"
                data-test-id="footer-link-disclosures-licenses"
              >
                Disclosures and Licenses
              </a>
            </MetricsElement>
          </div>
          <div className="rex-footer__company-info__licenses">
            <span className="rex-footer__company-info__liscense">AZ: CO685002000</span>
            <span className="rex-footer__company-info__liscense">CA: 01976010</span>
            <span className="rex-footer__company-info__liscense">NV: B.0145811.CORP</span>
            <span className="rex-footer__company-info__liscense">TX: 9006916</span>
          </div>
          <div>
            *REX all-inclusive fee starts at 2.5% of the final sales price. Minimum fee may apply.
            See terms and privacy.
          </div>
          {Array.isArray(additionalDisclaimers) &&
            additionalDisclaimers.map(({ symbol, text }) => (
              <div data-test-id="footerDisclaimer">
                {symbol}
                {text}
              </div>
            ))}
          <LazyLoadComponent>
            <a href="/terms-and-privacy/licensing-and-disclosures">
              <EqualHousingLogo className="rex-footer__equal-housing-logo" />
            </a>
          </LazyLoadComponent>
          <a
            className="rex-footer__bbb-link"
            href="https://www.bbb.org/losangelessiliconvalley/business-reviews/real-estate/rex-real-estate-exchange-in-woodland-hills-ca-873298#bbbseal"
            title="REX Real Estate Exchange, Inc., Real Estate, Woodland Hills, CA"
          >
            <LazyLoadImage
              className="rex-footer__bbb-logo"
              src="/assets/images/logos/bbb.svg"
              alt="REX Real Estate Exchange, Inc., Real Estate, Woodland Hills, CA"
            />
          </a>
        </div>
      </Box>
    </footer>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
  phoneNumber: PropTypes.string,
  email: PropTypes.string,
  additionalDisclaimers: PropTypes.arrayOf(
    PropTypes.shape({
      symbol: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ),
  isNarrow: PropTypes.bool,
  isFaqHidden: PropTypes.bool,
};

Footer.defaultProps = {
  className: '',
  phoneNumber: PHONE_NUMBER,
  email: EMAIL_ADDRESS,
  additionalDisclaimers: [],
  isNarrow: false,
  isFaqHidden: false,
};

const WithGlobalData = (props) => (
  <GlobalDataContext.Consumer>
    {({ phoneNumber }) => (
      // Disabled as this is a pass-through for non-context props
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Footer {...props} phoneNumber={phoneNumber} />
    )}
  </GlobalDataContext.Consumer>
);

WithGlobalData.propTypes = {
  className: Footer.propTypes.className,
  email: Footer.propTypes.email,
  additionalDisclaimers: Footer.propTypes.additionalDisclaimers,
};

WithGlobalData.defaultProps = {
  className: Footer.defaultProps.className,
  email: Footer.defaultProps.email,
  additionalDisclaimers: Footer.defaultProps.additionalDisclaimers,
};

export default WithGlobalData;
