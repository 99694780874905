import React from 'react';
import PropTypes from 'prop-types';
import { RexTypography } from '@rex-change/brix';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    margin: 'auto',
    maxWidth: theme.spacing(35),
    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.spacing(40),
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 'none',
    },
  },
  ratingStar: {
    height: theme.spacing(3),
    margin: theme.spacing(0, 0.375),
    [theme.breakpoints.up('lg')]: {
      height: theme.spacing(4),
    },
  },
  stars: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(0.75),
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  subtitleBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  zillowLogo: {
    height: theme.spacing(1.75),
    marginBottom: theme.spacing(2),
  },
}));

function ZillowCallout({ subtitleBoldCopy, subtitleCopy, titleCopy }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {titleCopy && (
        <RexTypography className={classes.title} variant="h6" component="h2">
          {titleCopy}
        </RexTypography>
      )}
      {(subtitleCopy || subtitleBoldCopy) && (
        <RexTypography className={classes.subtitle} variant="body1">
          {subtitleCopy} <span className={classes.subtitleBold}>{subtitleBoldCopy}</span>
        </RexTypography>
      )}
      <div className={classes.stars}>
        <img
          alt="Full rating star"
          className={classes.ratingStar}
          src="/assets/images/compare/star-solid.svg"
        />
        <img
          alt="Full rating star"
          className={classes.ratingStar}
          src="/assets/images/compare/star-solid.svg"
        />
        <img
          alt="Full rating star"
          className={classes.ratingStar}
          src="/assets/images/compare/star-solid.svg"
        />
        <img
          alt="Full rating star"
          className={classes.ratingStar}
          src="/assets/images/compare/star-solid.svg"
        />
        <img
          alt="Half rating star"
          className={classes.ratingStar}
          src="/assets/images/compare/star-half.svg"
        />
      </div>
      <img
        alt="Zillow logo"
        className={classes.zillowLogo}
        src="/assets/images/compare/zillow-logo.svg"
      />
    </div>
  );
}

ZillowCallout.propTypes = {
  subtitleBoldCopy: PropTypes.string,
  subtitleCopy: PropTypes.string,
  titleCopy: PropTypes.string,
};

ZillowCallout.defaultProps = {
  subtitleBoldCopy: 'find your local REX agent!',
  subtitleCopy: 'See reviews from our customers and',
  titleCopy: '”Not Your Typical Agent”',
};

export default ZillowCallout;
