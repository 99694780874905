import React, { useState } from 'react';
import { RexTypography, RexButton } from '@rex-change/brix';
import { makeStyles, Tabs, Tab } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { LottieLoadable } from 'components/LottiePlayer';
import InsuranceIcon from 'img/svg/icon-insurance.svg';
import loansAnimation from './loans-animation.json';
import insuranceAnimation from './insurance-animation.json';

const TABS = {
  LOANS: 'LOANS',
  INSURANCE: 'INSURANCE',
};

const defaultAnimationOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const loansAnimationOptions = {
  ...defaultAnimationOptions,
  animationData: loansAnimation,
};

const insuranceAnimationOptions = {
  ...defaultAnimationOptions,
  animationData: insuranceAnimation,
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3.5, 3),
  },
  title: {
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(2),
    },
  },
  heroImageWrapper: {
    margin: 'auto',
    maxWidth: '100%',
    height: theme.spacing(35),
    width: theme.spacing(35),
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(37.5),
      width: theme.spacing(37.5),
    },
    [theme.breakpoints.up('lg')]: {
      height: theme.spacing(40),
      width: theme.spacing(40),
    },
    [theme.breakpoints.up('xl')]: {
      height: theme.spacing(42),
      width: theme.spacing(42),
    },
  },
  tabImageContainer: {
    paddingTop: 0,
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  tabCopy: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  ctaContainer: {
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.spacing(70),
    },
  },
  appLinksContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-around',
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-between',
    },
  },
  appLink: {
    flexGrow: 1,
    '&:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.spacing(19),
    },
  },
  cta: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    '&:last-child': {
      marginTop: 0,
    },
  },
  buttonIcon: {
    marginBottom: '0.05em',
  },
}));

function Services() {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(TABS.LOANS);

  return (
    <div className={classes.root}>
      <RexTypography variant="h4" className={classes.title}>
        Integrated Services
      </RexTypography>
      <TabContext value={selectedTab}>
        <TabPanel value={TABS.LOANS} className={classes.tabImageContainer}>
          <RexTypography variant="subtitle2" className={classes.title}>
            Mortgages are complicated - we keep it simple.
          </RexTypography>
          <div className={classes.heroImageWrapper}>
            <LottieLoadable defaultOptions={loansAnimationOptions} data-testid="loans-image" />
          </div>
        </TabPanel>
        <TabPanel value={TABS.INSURANCE} className={classes.tabImageContainer}>
          <RexTypography variant="subtitle2" className={classes.title}>
            No one plans for accidents, but we are always prepared.
          </RexTypography>
          <div className={classes.heroImageWrapper}>
            <LottieLoadable
              defaultOptions={insuranceAnimationOptions}
              data-testid="insurance-image"
            />
          </div>
        </TabPanel>
        <Tabs
          variant="fullWidth"
          value={selectedTab}
          onChange={(event, value) => setSelectedTab(value)}
        >
          <Tab
            value={TABS.LOANS}
            label={<RexTypography variant="h6">REX Home Loans</RexTypography>}
            data-testid="loans-tab"
          />
          <Tab
            value={TABS.INSURANCE}
            label={<RexTypography variant="h6">Insurance</RexTypography>}
            data-testid="insurance-tab"
          />
        </Tabs>
        <TabPanel className={classes.tabCopy} value={TABS.LOANS} data-testid="loans-content">
          <RexTypography variant="body1">
            Let REX compare your rates with our Mortgage Calculator, get you pre-approved online,
            and secure the home loan that’s right for you.
          </RexTypography>
          <div className={classes.ctaContainer}>
            <RexButton className={classes.cta} fullWidth href="/home-loans">
              Get a home loan
            </RexButton>
            <div className={classes.appLinksContainer}>
              <a
                className={classes.appLink}
                href="https://apps.apple.com/us/app/rex-real-estate/id1450443888"
              >
                <img
                  src="/assets/images/icons/app-store.svg"
                  alt="Apple App Store"
                />
              </a>
              <a
                className={classes.appLink}
                href="https://play.google.com/store/apps/details?id=com.rexapp"
              >
                <img
                  src="/assets/images/icons/google-play-badge.svg"
                  alt="Play Google Store"
                />
              </a>
            </div>
          </div>
        </TabPanel>
        <TabPanel
          className={classes.tabCopy}
          value={TABS.INSURANCE}
          data-testid="insurance-content"
        >
          <RexTypography variant="body1">
            Protect your home, assets and bundle coverage for your vehicles, motorcycles, and more.
            Since we don’t represent the insurance carriers, you’ll always get the best rate
            possible.
          </RexTypography>
          <div className={classes.ctaContainer}>
            <RexButton className={classes.cta} fullWidth href="/service/insurance/home">
              Request a Quote
            </RexButton>
            <RexButton
              className={classes.cta}
              fullWidth
              variant="text"
              endIcon={<InsuranceIcon className={classes.buttonIcon} />}
              href="/service/insurance"
            >
              View Insurance Coverage
            </RexButton>
          </div>
        </TabPanel>
      </TabContext>
    </div>
  );
}

export default Services;
