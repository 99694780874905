import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import { FAILURE, INITIAL, REQUEST, SUCCESS } from 'constants/fetchStates';

function fetchHydrateData() {
  if (__CLIENT__ && window.hydrateData) {
    const data = window.hydrateData;
    delete window.hydrateData;
    return data;
  }

  return null;
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 100,
  },
  loading: {
    padding: theme.spacing(2),
    borderRadius: '50%',
    backgroundColor: theme.palette.common.white,
    boxSizing: 'content-box',
  },
}));

const withPage = (WrappedComponent, { fetchData }) => {
  function Page(props) {
    const { staticContext } = props;
    const hasFetchData = typeof fetchData === 'function';
    const initialData = staticContext?.data || fetchHydrateData();
    const [data, setData] = useState(initialData || {});
    const [loadingDataState, setLoadingDataState] = useState(
      initialData || !hasFetchData ? SUCCESS : INITIAL,
    );
    const classes = useStyles();

    useEffect(() => {
      async function fetchPageData() {
        setLoadingDataState(REQUEST);
        try {
          const newData = await fetchData(props);
          setData(newData);
          setLoadingDataState(SUCCESS);
        } catch (error) {
          setLoadingDataState(FAILURE);
        }
      }

      if (!initialData && hasFetchData) {
        // Client-side fetching
        fetchPageData();
      }
    }, [hasFetchData, initialData, props]);

    useEffect(() => {
      window.marketingData = data?.marketingData ?? {};
    }, [data]);

    // Disabled as this is a pass-through for passed props
    /* eslint-disable react/jsx-props-no-spreading */
    return (
      <>
        {(loadingDataState === INITIAL || loadingDataState === REQUEST) && (
          <Backdrop open className={classes.backdrop}>
            <CircularProgress color="primary" className={classes.loading} />
          </Backdrop>
        )}
        <WrappedComponent {...props} {...data} loadingDataState={loadingDataState} />
      </>
    );
    /* eslint-enable react/jsx-props-no-spreading */
  }

  Page.propTypes = {
    staticContext: PropTypes.shape({
      data: PropTypes.shape({}),
    }),
    location: PropTypes.shape({
      search: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    }),
    requiresAccount: PropTypes.bool,
  };

  Page.defaultProps = {
    staticContext: {},
    location: null,
    requiresAccount: false,
  };

  return Page;
};

export default withPage;
