import React from 'react';
import withPage from 'containers/RexPage';
import { SEO } from 'components/atoms/SEO';
import { Container, makeStyles } from '@material-ui/core';
import Hero from 'components/HomePage/Hero';
import HowItWorks from 'components/HomePage/HowItWorks';
import Services from 'components/HomePage/Services';
import Spoke from 'components/HomePage/Spoke';
import TestimonialCarousel from 'components/TestimonialCarousel';
import Differentiation from 'components/HomePage/Differentiation';
import ContactUs from 'components/ContactUs';
import Header from 'components/Header';
import Footer from 'components/Footer';
import ZillowCallout from 'components/ZillowCallout';
import Route from './HomePage.route';
import content from './content.json';

const useStyles = makeStyles((theme) => ({
  sectionWrapper: {
    backgroundColor: theme.palette.grey[50],
    '&:nth-child(2n)': {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

export function Home() {
  const classes = useStyles();
  const { testimonials } = content;

  return (
    <>
      <SEO
        canonicalPath="/"
        description="Keep the equity when you sell and get more savings when you buy. REX is everything you need to buy, sell, finance, manage, insure, and save on your next home."
      />
      <div className={classes.sectionWrapper}>
        <Header />
        <Hero />
      </div>
      <section className={classes.sectionWrapper}>
        <Differentiation />
      </section>
      <section className={classes.sectionWrapper}>
        <Container disableGutters>
          <HowItWorks />
        </Container>
      </section>
      <section className={classes.sectionWrapper}>
        <Container disableGutters>
          <Services />
        </Container>
      </section>
      <section className={classes.sectionWrapper}>
        <Container disableGutters>
          <Spoke />
        </Container>
      </section>
      <section className={classes.sectionWrapper}>
        <Container disableGutters>
          <TestimonialCarousel
            testimonials={testimonials}
            title="Our users saved up to $23k in agent fees"
          />
          <ZillowCallout titleCopy="" />
        </Container>
        <ContactUs />
      </section>
      <Footer />
    </>
  );
}

export default withPage(Home, Route);
