export const DEFAULT_BASE_IMAGE = '';
export const MAX_IMAGE_WIDTH = 3850;
export const SRCSET_STEP_WIDTH = 50;

function getValidTypeValue(value, defaultValue) {
  if (typeof value !== typeof defaultValue) {
    return defaultValue;
  }

  return value;
}

export const getImgSrc = (src = '', width = '') => {
  const [baseUrl, query] = src.split('?', 2);

  let imgSrc = baseUrl;

  if (query) {
    imgSrc = `${imgSrc}?${query}`;
  }

  if (query && width) {
    imgSrc = `${imgSrc}&width=${width}`;
  } else if (width) {
    imgSrc = `${imgSrc}?width=${width}`;
  }

  return imgSrc;
};

export function getImgSrcSet(imageUrl, maxWidth = MAX_IMAGE_WIDTH, step = SRCSET_STEP_WIDTH) {
  const baseImageUrl = getValidTypeValue(imageUrl, DEFAULT_BASE_IMAGE);
  let maxImgWidth = getValidTypeValue(maxWidth, MAX_IMAGE_WIDTH);
  let widthStep = getValidTypeValue(step, SRCSET_STEP_WIDTH);

  if (widthStep <= 0) {
    widthStep = SRCSET_STEP_WIDTH;
  }
  if (maxImgWidth < widthStep) {
    maxImgWidth = MAX_IMAGE_WIDTH;
    if (maxImgWidth < widthStep) {
      widthStep = SRCSET_STEP_WIDTH;
    }
  }

  if (!baseImageUrl) {
    return '';
  }

  const images = [];
  const [baseUrl, query] = baseImageUrl.split('?', 2);
  const queryParams = new URLSearchParams(query);
  queryParams.delete('width');

  for (let width = widthStep; width <= maxImgWidth; width += widthStep) {
    queryParams.set('width', width);
    const image = `${baseUrl}?${queryParams}`;
    images.push(`${image} ${width}w`);
  }

  return images.join(', ');
}
