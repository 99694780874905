import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import ENV from 'constants/env';

function SEO({ canonicalPath, children, description, image, title }) {
  return (
    <Helmet>
      {/* Helmet does not like Framents (</>) to combine these */}
      {title && <title>{title}</title>}
      {title && <meta property="og:title" content={title} />}

      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}

      {image && <meta property="og:image" content={image} />}

      {canonicalPath && <link rel="canonical" href={`${ENV.REX_CANONICAL_HOST}${canonicalPath}`} />}

      {children}
    </Helmet>
  );
}

SEO.propTypes = {
  canonicalPath: PropTypes.string,
  children: PropTypes.node,
  description: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
};

SEO.defaultProps = {
  canonicalPath: null,
  children: null,
  description: null,
  image: null,
  title: null,
};

export default SEO;
