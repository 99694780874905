import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BuyerIcon from 'img/svg/icon-buyer.svg';
import SellerIcon from 'img/svg/icon-seller.svg';
import { RexTypography, RexButton } from '@rex-change/brix';
import { makeStyles, Tabs, Tab } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import clsx from 'clsx';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

const TABS = {
  BUYING: 'BUYING',
  SELLING: 'SELLING',
};

const SELLING_CONTENT = [
  {
    heroImageSrc: '/assets/images/home-page/HowItWorks-Seller-1.svg',
    heading: 'Welcome to REX',
    description:
      'Meet with our pricing expert to identify your needs and develop your roadmap to selling with REX.',
  },
  {
    heroImageSrc: '/assets/images/home-page/HowItWorks-Seller-2.svg',
    heading: 'Prepare Listing',
    description:
      'Meet with your dedicated account manager to set up your listing and get the ball rolling.',
  },
  {
    heroImageSrc: '/assets/images/home-page/HowItWorks-Seller-3.svg',
    heading: 'Go Live',
    description:
      'Once your listing goes live, you can check out Market data, buyer activity, and upcoming showings from your dashboard.',
  },
  {
    heroImageSrc: '/assets/images/home-page/HowItWorks-Seller-4.svg',
    heading: 'Enter Escrow',
    description:
      'Simplify your transaction and save money by choosing our in-house title and escrow service.',
  },
  {
    heroImageSrc: '/assets/images/home-page/HowItWorks-Seller-5.svg',
    heading: 'Close',
    description:
      'See how much you’ve saved by using non-commissioned agents with REX, and celebrate your big win!',
  },
];

const BUYING_CONTENT = [
  {
    heroImageSrc: '/assets/images/home-page/HowItWorks-Buyer-1.svg',
    heading: 'Welcome to REX',
    description:
      'First, meet the team of dedicated, licensed agents that work with you from offer to close.',
  },
  {
    heroImageSrc: '/assets/images/home-page/HowItWorks-Buyer-2.svg',
    heading: 'Set Your Budget',
    description:
      'Start your search by setting a budget, and secure your financing with REX Home Loans today!',
  },
  {
    heroImageSrc: '/assets/images/home-page/HowItWorks-Buyer-3.svg',
    heading: 'Find Your Home',
    description:
      'Find, visit, and make an offer on your dream home — you can do it all in the same day!',
  },
  {
    heroImageSrc: '/assets/images/home-page/HowItWorks-Buyer-4.svg',
    heading: 'Offer & Escrow',
    description:
      'Our logistics team will be with you every step of the way to close; you can monitor progress through our mobile app.',
  },
  {
    heroImageSrc: '/assets/images/home-page/HowItWorks-Buyer-5.svg',
    heading: 'Close',
    description:
      'Congratulations on your new home! Need anything else? We can help with much more like moving, insurance, and renovations with our bundled services.',
  },
];

const useContentCarouselStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
  },
  description: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
  },
  hero: {
    position: 'relative',
    padding: theme.spacing(7),
  },
  number: {
    position: 'absolute',
    left: theme.spacing(3),
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: theme.palette.grey[800],
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: '50%',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.common.white,
    fontWeight: 'bold',
    zIndex: -1,
    [theme.breakpoints.up('md')]: {
      left: `calc(50% - ${theme.spacing(21)}px)`,
    },
    [theme.breakpoints.up('lg')]: {
      left: `calc(50% - ${theme.spacing(25)}px)`,
    },
  },
  connectorLine: {
    position: 'absolute',
    left: '0',
    right: '0',
    top: '50%',
    borderTop: '1px solid',
    borderTopColor: theme.palette.grey[800],
    zIndex: -1,
  },
  firstLine: {
    left: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      left: `calc(50% - ${theme.spacing(21)}px)`,
    },
    [theme.breakpoints.up('lg')]: {
      left: `calc(50% - ${theme.spacing(25)}px)`,
    },
  },
  lastLine: {
    right: `calc(100% - ${theme.spacing(3)}px)`,
    [theme.breakpoints.up('md')]: {
      right: `calc(50% + ${theme.spacing(21)}px)`,
    },
    [theme.breakpoints.up('lg')]: {
      right: `calc(50% + ${theme.spacing(25)}px)`,
    },
  },
  image: {
    display: 'block',
    margin: '0 auto',
    textAlign: 'center',
    width: theme.spacing(20.75),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(25),
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(31),
    },
    [theme.breakpoints.up('lg')]: {
      width: theme.spacing(38),
    },
  },
  copy: {
    padding: theme.spacing(0, 3.5),
  },
  navigationButtons: {
    '&:after': {
      // This is an override for Swiper's css which necessitates !important
      // This should be temporary and removed once we clean up the carousels
      fontSize: `${theme.spacing(2.5)}px !important`,
      color: `${theme.palette.grey[800]} !important`,
    },
  },
}));

function ContentCarousel({ content }) {
  const classes = useContentCarouselStyles();

  return (
    <Swiper
      navigation={{
        prevEl: `.swiper-button-prev.${classes.navigationButtons}`,
        nextEl: `.swiper-button-next.${classes.navigationButtons}`,
      }}
    >
      {Array.isArray(content) &&
        content.map(({ heroImageSrc, heading, description }, index) => (
          <div key={heading}>
            <div className={classes.hero}>
              <div
                className={clsx(
                  classes.connectorLine,
                  index === 0 && classes.firstLine,
                  index === content.length - 1 && classes.lastLine,
                )}
              />
              <div className={classes.number}>{index + 1}</div>
              <img className={classes.image} src={heroImageSrc} alt="" />
            </div>
            <div className={classes.copy}>
              <RexTypography className={classes.heading} variant="h6">
                {heading}
              </RexTypography>
              <RexTypography variant="body2" className={classes.description}>
                {description}
              </RexTypography>
            </div>
          </div>
        ))}
    </Swiper>
  );
}

ContentCarousel.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      heroImageSrc: PropTypes.string.isRequired,
      heading: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3.5, 3),
  },
  heading: {
    marginBottom: theme.spacing(2.5),
  },
  panel: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  ctaButton: {
    margin: theme.spacing(0.5, 0),
  },
  carouselWrapper: {
    margin: theme.spacing(0, -3.5, 5),
  },
  ctaContainer: {
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.spacing(70),
    },
  },
  buttonIcon: {
    marginBottom: '0.05em',
  },
}));

function HowItWorks() {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(TABS.SELLING);

  return (
    <div className={classes.root}>
      <RexTypography className={classes.heading} variant="h4" align="center">
        How it all works
      </RexTypography>
      <TabContext value={selectedTab}>
        <Tabs
          variant="fullWidth"
          value={selectedTab}
          onChange={(event, value) => setSelectedTab(value)}
        >
          <Tab
            value={TABS.SELLING}
            label={<RexTypography variant="h6">Selling</RexTypography>}
            data-testid="selling-tab"
          />
          <Tab
            value={TABS.BUYING}
            label={<RexTypography variant="h6">Buying</RexTypography>}
            data-testid="buying-tab"
          />
        </Tabs>
        <TabPanel className={classes.panel} value={TABS.SELLING} data-testid="selling-content">
          <div className={classes.carouselWrapper}>
            <ContentCarousel content={SELLING_CONTENT} />
          </div>
          <div className={classes.ctaContainer}>
            <RexButton
              className={classes.ctaButton}
              fullWidth
              endIcon={<SellerIcon className={classes.buttonIcon} />}
              href="/listingAgreement"
            >
              Start selling with REX
            </RexButton>
            <RexButton className={classes.ctaButton} fullWidth variant="text" href="/sell-with-rex">
              Learn More
            </RexButton>
          </div>
        </TabPanel>
        <TabPanel className={classes.panel} value={TABS.BUYING} data-testid="buying-content">
          <div className={classes.carouselWrapper}>
            <ContentCarousel content={BUYING_CONTENT} />
          </div>
          <div className={classes.ctaContainer}>
            <RexButton
              className={classes.ctaButton}
              fullWidth
              endIcon={<BuyerIcon className={classes.buttonIcon} />}
              href="/homes"
            >
              Start buying with REX
            </RexButton>
            <RexButton className={classes.ctaButton} fullWidth variant="text" href="/buy-with-rex">
              Learn More
            </RexButton>
          </div>
        </TabPanel>
      </TabContext>
    </div>
  );
}

export default HowItWorks;
