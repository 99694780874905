import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RexTypography, Link } from '@rex-change/brix';
import {
  makeStyles,
  useTheme,
  useMediaQuery,
  Container,
  Tabs,
  Tab,
  Button,
} from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import generateBoundsFromCenter from 'helpers/generateBoundsFromCenter';
import GoogleAutocomplete, { SEARCH_TYPES } from 'components/GoogleAutocomplete';
import AddressSearch from 'components/HumanlessListingFlow/AddressSearch';
import { LottieLoadable } from 'components/LottiePlayer';
import FEATURED_ON_IMAGES from './featuredOnImages';
import animationLeft from './Hero-Animation-Left.json';
import animationRight from './Hero-Animation-Right.json';

const TABS = {
  BUY: 'BUY',
  SELL: 'SELL',
};

const defaultAnimationOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const animationLeftOptions = {
  ...defaultAnimationOptions,
  animationData: animationLeft,
};

const animationRightOptions = {
  ...defaultAnimationOptions,
  animationData: animationRight,
};

const useTabStyles = makeStyles((theme) => ({
  tabCopy: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),

    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1.5),
    },
  },
  tabLabel: {
    color: 'inherit',
  },
  selectedTabLabel: {
    color: theme.palette.primary.main,
  },
  tabIndicator: {
    backgroundColor: theme.palette.primary.main,
  },
  buyerCtaWrapper: {
    display: 'flex',
    justifyContent: 'stretch',
  },
  buyerCtaField: {
    flexGrow: 1,
    marginRight: theme.spacing(1),
  },
  sellerCta: {
    alignItems: 'flex-start',
    display: 'flex',
  },
  sellerCtaField: {
    display: 'block',
  },
  sellerCtaButton: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1.25, 2),
  },
}));

function TabContent({ buyerLookupBounds, onBuyAddressLookup, selectedTab, setSelectedTab }) {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useTabStyles({ selectedTab });

  return (
    <TabContext value={selectedTab}>
      <Tabs
        classes={{ indicator: classes.tabIndicator }}
        variant="fullWidth"
        value={selectedTab}
        onChange={(event, value) => setSelectedTab(value)}
      >
        <Tab
          value={TABS.SELL}
          classes={{ selected: classes.selectedTabLabel }}
          label={
            <RexTypography variant="h6" className={classes.tabLabel}>
              Sell
            </RexTypography>
          }
          data-testid="sell-tab"
        />
        <Tab
          value={TABS.BUY}
          classes={{ selected: classes.selectedTabLabel }}
          label={
            <RexTypography variant="h6" className={classes.tabLabel}>
              Buy
            </RexTypography>
          }
          data-testid="buy-tab"
        />
      </Tabs>
      <TabPanel className={classes.tabCopy} value={TABS.BUY} data-testid="buy-content">
        <div className={classes.buyerCtaWrapper}>
          <GoogleAutocomplete
            className={classes.buyerCtaField}
            onChange={onBuyAddressLookup}
            size="small"
            searchTypes={[SEARCH_TYPES.ADDRESS, SEARCH_TYPES.REGION]}
            placeholder={isMdUp ? 'Enter city, zipcode, or address' : 'Enter city or zipcode'}
          />
          <Button
            color="secondary"
            variant="contained"
            component={Link}
            to={`/homes?${buyerLookupBounds}`}
          >
            {isMdUp ? 'GET STARTED' : 'GO'}
          </Button>
        </div>
      </TabPanel>
      <TabPanel className={classes.tabCopy} value={TABS.SELL} data-testid="sell-content">
        <AddressSearch
          isRow
          containerStyles={classes.sellerCta}
          suggestWrapStyles={classes.sellerCtaField}
          buttonStyles={classes.sellerCtaButton}
          submitButtonCopy={isMdUp ? 'GET STARTED' : 'GO'}
          placeholder="Enter your home address"
          buttonColor="secondary"
        />
      </TabPanel>
    </TabContext>
  );
}

TabContent.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  buyerLookupBounds: PropTypes.string.isRequired,
  onBuyAddressLookup: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  hero: {
    position: 'relative',
    width: '100%',
    backgroundImage: 'url(/assets/images/home-page/hero-spacer.svg)',
    backgroundSize: '100% 100%',
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(-4),
      marginBottom: theme.spacing(-2),
    },
  },
  heroImage: {
    display: 'block',
    width: '100%',
  },
  leftHeroWrapper: {
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(56.25),
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(46.25),
    },
    [theme.breakpoints.up('lg')]: {
      width: theme.spacing(60),
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: theme.spacing(85),
    },
  },
  rightHeroWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      width: theme.spacing(46.25),
    },
    [theme.breakpoints.up('lg')]: {
      width: theme.spacing(60),
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: theme.spacing(85),
    },
  },
  animationWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  contentContainer: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    right: theme.spacing(2),
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.spacing(64),
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 'none',
      top: theme.spacing(7.5),
    },
  },
  xsSmTabContainer: {
    padding: theme.spacing(0, 2.5, 2),
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.spacing(48),
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  mdUpTabContainer: {
    display: 'none',
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      marginTop: theme.spacing(5),
      maxWidth: theme.spacing(56.25),
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: theme.spacing(67.5),
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: theme.spacing(90),
    },
  },
  heading: {
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
  },
  copy: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
  },
  featuredOnPositioner: {
    position: 'relative',
    height: 0,
    paddingBottom: `calc(15% + ${theme.spacing(6)}px)`,
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(4.5),
      paddingBottom: `calc(5% + ${theme.spacing(2)}px)`,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(8),
    },
  },
  featuredOnWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    padding: theme.spacing(0, 2, 6),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 0, 3),
      flexWrap: 'nowrap',
    },
  },
  featuredOnImage: {
    height: '30%',
    minWidth: 0,
    [theme.breakpoints.up('md')]: {
      height: '45%',
    },
  },
  zillowContainer: {
    textAlign: 'center',
    paddingBottom: theme.spacing(2),
  },
}));

function Hero() {
  const classes = useStyles();
  const [buyerLookupBounds, setBuyerLookupBounds] = useState('');
  const [selectedTab, setSelectedTab] = useState(TABS.SELL);

  const onBuyAddressLookup = (address, coordinates, boundingBox) => {
    const bounds = boundingBox || generateBoundsFromCenter(coordinates);
    const boundsQuery = new URLSearchParams({ bounds });
    setBuyerLookupBounds(boundsQuery.toString());
  };

  return (
    <div className={classes.root}>
      <div className={classes.hero}>
        <div className={classes.leftHeroWrapper}>
          <img
            className={classes.heroImage}
            src="/assets/images/home-page/hero-left.svg"
            alt=""
          />
          <div className={classes.animationWrapper}>
            <LottieLoadable defaultOptions={animationLeftOptions} data-testid="animation-left" />
          </div>
        </div>
        <div className={classes.rightHeroWrapper}>
          <img
            className={classes.heroImage}
            src="/assets/images/home-page/hero-right.svg"
            alt=""
          />
          <div className={classes.animationWrapper}>
            <LottieLoadable defaultOptions={animationRightOptions} data-testid="animation-right" />
          </div>
        </div>
      </div>
      <div className={classes.contentContainer}>
        <RexTypography variant="h5" className={classes.heading}>
          {selectedTab === TABS.BUY && 'Buy, Sell, & '}
          {selectedTab === TABS.SELL && 'Sell, Buy, & '}
          Manage your Home
        </RexTypography>
        <RexTypography variant="subtitle2" className={classes.copy}>
          Lower fees, better service, everything you need.
        </RexTypography>
        <Container disableGutters className={classes.mdUpTabContainer}>
          <TabContent
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            buyerLookupBounds={buyerLookupBounds}
            onBuyAddressLookup={onBuyAddressLookup}
          />
        </Container>
      </div>
      <Container disableGutters className={classes.xsSmTabContainer}>
        <TabContent
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          buyerLookupBounds={buyerLookupBounds}
          onBuyAddressLookup={onBuyAddressLookup}
        />
      </Container>
      <Container disableGutters className={classes.featuredContainer}>
        <div className={classes.featuredOnPositioner}>
          <div className={classes.featuredOnWrapper}>
            {FEATURED_ON_IMAGES.map(({ id, src, alt }) => (
              <img className={classes.featuredOnImage} key={id} src={src} alt={alt} />
            ))}
          </div>
        </div>
      </Container>
      <Container disableGutters className={classes.zillowContainer}>
        <img src="/assets/images/zillow-rating.svg" alt="zillow rating" />
      </Container>
    </div>
  );
}

export default Hero;
