import clsx from 'clsx';
import throttle from 'lodash/throttle';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography, TextField, useTheme } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import loadGooglePlaces from 'helpers/googlePlaces';
import parse from 'autosuggest-highlight/parse';

const THROTTLE_DELAY_MS = 200;

export const SEARCH_TYPES = {
  ADDRESS: 'ADDRESS',
  REGION: 'REGION',
};

const DATA_FIELDS = ['formatted_address', 'geometry', 'types'];
const RESTRICTIONS = { country: 'us' };
const ADDRESS_BLACKLIST = ['route'];

const CONFIGS = {
  ADDRESS: {
    types: ['address'],
  },
  REGION: {
    types: ['(regions)'],
  },
};

function filterBlacklistedOptions(options, searchTypes) {
  if (
    Array.isArray(searchTypes) &&
    searchTypes.length === 1 &&
    searchTypes[0] === SEARCH_TYPES.ADDRESS
  ) {
    return options.filter(
      (option) => !option?.types?.find((type) => ADDRESS_BLACKLIST.includes(type)),
    );
  }

  return options;
}

// rex-app parsing logic fails for the full address
// so we are accounting for it here.
function normalizeAddressForRexApp(address = '') {
  return address?.replace ? address.replace(', USA', '') : null;
}

const autocompleteService = { current: null };
const placesService = { current: null };

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
  },
  details: {
    display: 'none',
  },
}));

function renderOption(option, theme) {
  // Disabled as these properties come from Google Places
  /* eslint-disable camelcase */
  const matches = option?.structured_formatting?.main_text_matched_substrings;
  const parts = parse(
    option?.structured_formatting.main_text,
    matches.map((match) => [match?.offset, match?.offset + match?.length]),
  );

  return (
    <div>
      <Typography variant="body1" component="div">
        {parts.map((part, index) => (
          <span
            // Disabled as third party provides no unique key
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            style={{
              fontWeight: part?.highlight
                ? theme?.typography?.fontWeightBold
                : theme?.typography?.fontWeightRegular,
            }}
          >
            {part?.text}
          </span>
        ))}
      </Typography>
      <Typography variant="body2" color="textSecondary" component="div">
        {option?.structured_formatting?.secondary_text}
      </Typography>
    </div>
  );
  /* eslint-enable camelcase */
}

function GoogleAutocomplete({ onChange, className, placeholder, searchTypes, ...props }) {
  const classes = useStyles();
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const theme = useTheme();
  const loaded = useRef(false);
  // Places details requires an HTML element to function
  const detailsElem = useRef(null);

  if (__CLIENT__ && !loaded?.current) {
    loadGooglePlaces();

    loaded.current = true;
  }

  const fetchPlaces = React.useMemo(
    () =>
      throttle((input, types, callback) => {
        if (Array.isArray(types)) {
          Object.values(SEARCH_TYPES).forEach((type) => {
            if (types.includes(type)) {
              const config = {
                ...CONFIGS[type],
                componentRestrictions: RESTRICTIONS,
              };
              const request = { ...config, input };
              // Linter disabled as it does not understand optional chaining function calls yet
              // eslint-disable-next-line no-unused-expressions
              autocompleteService?.current?.getPlacePredictions?.(request, callback);
            }
          });
        }
      }, THROTTLE_DELAY_MS),
    [],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService?.current && window?.google?.maps?.places?.AutocompleteService) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }

    if (
      !placesService?.current &&
      detailsElem?.current &&
      window?.google?.maps?.places?.PlacesService
    ) {
      placesService.current = new window.google.maps.places.PlacesService(detailsElem.current);
    }

    // Failed to load
    if (!autocompleteService?.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    let isFirstCall = true;
    fetchPlaces(inputValue, searchTypes, (results) => {
      if (active) {
        const newOptions = value ? [value] : [];

        if (results) {
          newOptions.push(...results);
        }

        if (isFirstCall) {
          isFirstCall = false;
          setOptions(newOptions);
        } else {
          setOptions((currentOptions) => [...currentOptions, ...newOptions]);
        }
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetchPlaces, searchTypes]);

  const renderThemedOption = useCallback(
    (option) => {
      return renderOption(option, theme);
    },
    [theme],
  );

  const onSelectAddress = useCallback(
    (event, newValue) => {
      setOptions(newValue ? [newValue, ...options] : options);
      setValue(newValue);
      if (onChange && placesService?.current && newValue) {
        placesService.current.getDetails(
          { placeId: newValue.place_id, fields: DATA_FIELDS },
          (result) => {
            const { formatted_address: formattedAddress, geometry } = result;
            const location = geometry?.location;
            const viewport = geometry?.viewport;
            const coordinates = location ? [location.lat(), location.lng()] : null;
            const boundingBox = viewport
              ? [
                  viewport.getSouthWest().lng(),
                  viewport.getSouthWest().lat(),
                  viewport.getNorthEast().lng(),
                  viewport.getNorthEast().lat(),
                ]
              : null;
            const rexappifiedAddress = normalizeAddressForRexApp(formattedAddress);
            onChange(rexappifiedAddress, coordinates, boundingBox, setValue);
          },
        );
      }
    },
    [options, onChange],
  );

  return (
    <>
      <div ref={detailsElem} className={classes.details} />
      <Autocomplete
        className={clsx(className, classes.root)}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option?.description)}
        filterOptions={(allOptions) => filterBlacklistedOptions(allOptions, searchTypes)}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={onSelectAddress}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            // Disabled as this is a pass-through for TextField mui props
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            placeholder={placeholder}
            variant="outlined"
            fullWidth
          />
        )}
        renderOption={renderThemedOption}
        // Disabled as this is a pass-through for AutoComplete mui props
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </>
  );
}

GoogleAutocomplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  searchTypes: PropTypes.arrayOf(PropTypes.string),
};

GoogleAutocomplete.defaultProps = {
  className: null,
  placeholder: 'Enter your address',
  searchTypes: [SEARCH_TYPES.ADDRESS],
};

export default GoogleAutocomplete;
