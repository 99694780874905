import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  buttonWrap: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    margin: theme.spacing(2, 0),
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

function FlowButtonContainer({ className, children }) {
  const classes = useStyles();
  return <div className={clsx(classes.buttonWrap, className)}>{children}</div>;
}

FlowButtonContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

FlowButtonContainer.defaultProps = {
  className: '',
  children: null,
};

export default FlowButtonContainer;
