import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/Loading';

const LottieLoadable = Loadable({
  loader: () => import(/* webpackChunkName: "lottie-player" */ './LottiePlayer'),
  loading: Loading,
  render(loaded, { defaultOptions, height, width, isStopped, isPaused }) {
    const { default: Lottie } = loaded;

    return (
      <Lottie
        options={defaultOptions}
        height={height}
        width={width}
        isStopped={isStopped}
        isPaused={isPaused}
      />
    );
  },
});

export default LottieLoadable;
