export const MAP_STYLE = 'mapbox://styles/rexchange/cjginyjtn002n2rpj6s7kgyaa';
export const MAPBOX_TOKEN =
  'pk.eyJ1IjoicmV4Y2hhbmdlIiwiYSI6ImNqMWZlcGtnYjAwcTUzM2p1c21jcTd1MDUifQ.oRlTvxQuiNlDoUM8OMiMoQ';
export const DEFAULT_BOUNDS = [-124.848974, 24.396308, -66.885444, 49.384358];
export const DEFAULT_CENTER = [95.7129, 37.0902];
export const DEFAULT_ZOOM = 14;
export const MAX_LATITUDE = 90;
export const MIN_LATITUDE = -90;

// These values are used to generate a padded bounding box from
// center coordinates.
export const LATITUDE_PADDING = 0.05;
export const LONGITUDE_PADDING = 0.05;
