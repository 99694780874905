import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { PropTypes as MetricsPropTypes } from 'react-metrics';
import { getImgSrcSet, getImgSrc } from 'helpers/imageTransformations/imageTransformations';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: 'transparent',
    height: '100%',
    padding: theme.spacing(2.25),
    width: '100%',
  },
  card: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.spacing(1.5),
    margin: 'auto',
    maxWidth: theme.spacing(42),

    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.palette.common.white,
      width: '100%',
    },
  },
  cardContent: {
    color: theme.palette.grey[600],
    padding: theme.spacing(3),
  },
  avatar: {
    height: theme.spacing(10),
    width: theme.spacing(10),
    margin: '0 auto',
  },
  avatarImg: {
    flex: '1 1 100%',
    height: '100%',
    objectFit: 'cover',
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    height: theme.spacing(0.5),
    margin: theme.spacing(0, 'auto', 2.5),
    width: theme.spacing(5),
  },
  header: {
    textAlign: 'center',
  },
  name: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  quote: {
    maxHeight: theme.spacing(16),
    paddingBottom: theme.spacing(2),
    overflow: 'scroll',
  },
  textFade: {
    background: `linear-gradient(${fade(theme.palette.grey[50], 0)}, ${theme.palette.grey[50]})`,
    bottom: theme.spacing(2),
    height: theme.spacing(4),
    left: 0,
    position: 'absolute',
    width: '100%',

    [theme.breakpoints.up('md')]: {
      background: `linear-gradient(${fade(theme.palette.common.white, 0)}, ${
        theme.palette.common.white
      })`,
    },
  },
}));

function TestimonialCard({ imgSrc, imgAlt, quote, name }, { metrics }) {
  const classes = useStyles();
  const trackTestimonialClick = () =>
    metrics.track('click', { category: 'home-page', label: 'testimonial-card' });

  return (
    <div className={classes.wrapper}>
      <Card className={classes.card} raised>
        <CardActionArea
          href="/testimonials"
          onClick={trackTestimonialClick}
          data-rex-id="rex-ahcb-testimonial-card"
        >
          <CardContent className={classes.cardContent}>
            {imgSrc && (
              <Avatar className={classes.avatar}>
                <LazyLoadImage
                  className={classes.avatarImg}
                  alt={imgAlt}
                  src={getImgSrc(imgSrc, 112)}
                  srcSet={getImgSrcSet(imgSrc, 224, 16)}
                />
              </Avatar>
            )}
            <Typography variant="h6" className={classes.name} component="div">
              {name}
            </Typography>
            <Divider variant="middle" className={classes.divider} />
            <Typography variant="body1" className={classes.quote} component="p">
              {quote}
            </Typography>
            {/* If the character count exceeds the length of the text box, show a fade to indicate scrolling capability */}
            {quote?.length > 185 && <Box className={classes.textFade} />}
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}

TestimonialCard.propTypes = {
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
  quote: PropTypes.string,
  name: PropTypes.string,
};

TestimonialCard.defaultProps = {
  imgSrc: null,
  imgAlt: null,
  quote: null,
  name: null,
};

TestimonialCard.contextTypes = {
  metrics: MetricsPropTypes.metrics,
};

export default TestimonialCard;
