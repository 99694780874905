import { LATITUDE_PADDING, LONGITUDE_PADDING } from 'constants/mapbox';

const generateBoundsFromCenter = ([lat, lon]) => {
  if (lat && lon) {
    return [
      lon - LONGITUDE_PADDING,
      lat - LATITUDE_PADDING,
      lon + LONGITUDE_PADDING,
      lat + LATITUDE_PADDING,
    ];
  }
  return null;
};

export default generateBoundsFromCenter;
