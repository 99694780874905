import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import { styled } from '@material-ui/core';
import logger from 'utils/logger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import 'swiper/css/swiper.css';

export const CarouselWrap = styled('div')(({ theme, showSideArrows }) => ({
  alignItems: 'center',
  display: showSideArrows && 'flex',
  justifyContent: 'center',

  [theme.breakpoints.up('sm')]: {
    flexGrow: 1,
  },
}));

export const Nav = styled('nav')(({ theme }) => ({
  alignContent: 'center',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(2, 0),
}));

export const NavList = styled('ul')(({ theme }) => ({
  alignContent: 'center',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  listStyleType: 'none',
  padding: 0,
  '& li': {
    height: 'auto',
    margin: 0,
    marginLeft: theme.spacing(0.75),
    marginRight: theme.spacing(0.75),
    width: 'auto',
  },
}));

export const NavButton = styled('button')(({ theme }) => ({
  background: 'transparent',
  border: 0,
  color: theme.palette.grey[500],
  cursor: 'pointer',
  fontSize: theme.typography.h5.fontSize,
  lineHeight: 0.8,
  margin: theme.spacing(0, 3),
  outline: 0,
  padding: 0,
  [theme.breakpoints.up('lg')]: {
    margin: theme.spacing(0, 4),
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const NavDot = styled('button')(
  ({ theme, isActive, activeNavDotColor, baseNavDotColor }) => {
    const styles = {
      background: baseNavDotColor ?? theme.palette.grey[400],
      border: 0,
      height: theme.spacing(1.25),
      outline: 0,
      padding: 0,
      transitionProperty: 'background',
      transitionDuration: theme.transitions.duration.standard,
      transitionTimingFunction: theme.transitions.easing.easeInOut,
      width: theme.spacing(1.25),
    };

    if (isActive) {
      styles.background = activeNavDotColor ?? theme.palette.common.black;
    }

    return styles;
  },
);

export function Carousel(
  {
    children,
    on,
    loop,
    showSideArrows,
    buttonDataTestId,
    navStyles,
    navDotStyles,
    activeNavDotColor,
    baseNavDotColor,
    carouselWrapStyles,
    metricsCategory,
    metricsLabel,
    parentActiveIndex,
    setParentActiveIndex,
    isHideNavDots,
    ...props
  },
  { metrics },
) {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const swiperRef = React.useRef(null);

  const onDotClick = (index) => {
    if (!swiperRef.current || !swiperRef.current.swiper) {
      return;
    }
    if (loop) {
      swiperRef.current.swiper.slideToLoop(index);
    } else {
      swiperRef.current.swiper.slideTo(index);
    }
  };

  const onNextClick = () => {
    if (metrics) {
      metrics.track('click', {
        category: metricsCategory,
        label: `${metricsLabel}-next`,
      });
    }
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const onPrevClick = () => {
    if (metrics) {
      metrics.track('click', {
        category: metricsCategory,
        label: `${metricsLabel}-prev`,
      });
    }
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const updateStateFromSwiper = () => {
    if (!swiperRef.current || !swiperRef.current.swiper) {
      logger.warn('Could not update carousel state from swiper');
      return;
    }

    const { realIndex } = swiperRef.current.swiper;
    setActiveIndex(realIndex);

    if (setParentActiveIndex) {
      setParentActiveIndex(realIndex);
    }
  };

  const onTransitionEnd = () => {
    updateStateFromSwiper();

    if (on.transitionEnd) {
      on.transitionEnd();
    }
  };

  useEffect(() => {
    if (parentActiveIndex !== null) {
      if (!swiperRef?.current?.swiper) {
        return;
      }
      if (loop) {
        swiperRef.current.swiper.slideToLoop(parentActiveIndex);
      } else {
        swiperRef.current.swiper.slideTo(parentActiveIndex);
      }
    }
  }, [parentActiveIndex, loop]);

  return (
    <>
      <CarouselWrap showSideArrows={showSideArrows} className={carouselWrapStyles}>
        {showSideArrows && (
          <NavButton
            aria-label="previous"
            onClick={onPrevClick}
            data-test-id={`${buttonDataTestId}-arrow-previous`}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </NavButton>
        )}
        <Swiper
          ref={swiperRef}
          loop={loop}
          on={{
            ...on,
            transitionEnd: onTransitionEnd,
          }}
          // Disabled as this is a pass-through for Swiper props
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        >
          {children}
        </Swiper>
        {showSideArrows && (
          <NavButton
            aria-label="next"
            onClick={onNextClick}
            data-test-id={`${buttonDataTestId}-arrow-next`}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </NavButton>
        )}
      </CarouselWrap>
      {!isHideNavDots && (
        <Nav className={navStyles}>
          <NavList>
            {React.Children.map(children, (child, index) => {
              const isActive = index === activeIndex;

              return (
                <li key={child?.key}>
                  <NavDot
                    aria-label={`item ${index + 1}`}
                    onClick={() => onDotClick(index)}
                    isActive={isActive}
                    activeNavDotColor={activeNavDotColor}
                    baseNavDotColor={baseNavDotColor}
                    className={navDotStyles}
                  />
                </li>
              );
            })}
          </NavList>
        </Nav>
      )}
    </>
  );
}

Carousel.propTypes = {
  children: PropTypes.node,
  on: PropTypes.objectOf(PropTypes.func),
  loop: PropTypes.bool,
  carouselWrapStyles: PropTypes.shape({}),
  navStyles: PropTypes.shape({}),
  navDotStyles: PropTypes.shape({}),
  activeNavDotColor: PropTypes.string,
  baseNavDotColor: PropTypes.string,
  showSideArrows: PropTypes.bool,
  buttonDataTestId: PropTypes.string,
  metricsCategory: PropTypes.string,
  metricsLabel: PropTypes.string,
  parentActiveIndex: PropTypes.number,
  setParentActiveIndex: PropTypes.func,
  isHideNavDots: PropTypes.bool,
};

Carousel.defaultProps = {
  children: null,
  on: {},
  loop: false,
  carouselWrapStyles: {},
  navStyles: {},
  navDotStyles: {},
  activeNavDotColor: null,
  baseNavDotColor: null,
  showSideArrows: false,
  buttonDataTestId: 'carousel',
  metricsCategory: 'carousel',
  metricsLabel: 'carousel-toggle',
  parentActiveIndex: null,
  setParentActiveIndex: null,
  isHideNavDots: false,
};

export default Carousel;
