import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import { MetricsElement } from 'react-metrics';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(0, 0.5),
    width: 'auto',

    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0.5, 0),
      width: '100%',
    },
  },
}));

function SherpaPrimaryButton({
  children,
  className,
  color,
  trackingCategory,
  trackingLabel,
  ...props
}) {
  const classes = useStyles();
  // Disabled as this is a pass-through for Button props
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <MetricsElement>
      <Button
        data-metrics-event-name="click"
        data-metrics-category={trackingCategory}
        data-metrics-label={trackingLabel}
        variant="contained"
        color={color}
        className={`${classes.button} ${className}`}
        {...props}
      >
        {children}
      </Button>
    </MetricsElement>
  );
  /* eslint-enable react/jsx-props-no-spreading */
}

SherpaPrimaryButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  trackingCategory: PropTypes.string,
  trackingLabel: PropTypes.string,
};

SherpaPrimaryButton.defaultProps = {
  children: null,
  className: '',
  color: 'primary',
  trackingCategory: 'sherpa-primary-button',
  trackingLabel: 'unknown-area',
};

export default SherpaPrimaryButton;
