import React from 'react';
import { Box, Button, Container, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { RexTypography, RexButton } from '@rex-change/brix';
import Carousel from 'components/Carousel';

const SLIDE_DATA = [
  {
    title: 'Save thousands when you buy and sell',
    subtitle: 'Lower Fees',
    body: (
      <span>
        Our lower fees have <b>saved our customers millions of dollars</b> selling and buying homes
        across the USA.
      </span>
    ),
    cta: {
      copy: 'See Our Testimonials',
      url: '/testimonials',
    },
    imageUrl: '/assets/images/home-page/Lower-Fees-line.svg',
    imageUrlLarge: '/assets/images/home-page/Lower-Fees-Large.svg',
  },
  {
    title: 'A company homeowners trust',
    subtitle: 'Better Service',
    body: (
      <span>
        Our team of experts work to get you the best price for your house with the least fuss.
        That’s why we’ve earned a <b>5 STAR Zillow</b> rating and world-class consumer satisfaction
        scores.
      </span>
    ),
    cta: {
      copy: 'See Our Testimonials',
      url: '/testimonials',
    },
    imageUrl: '/assets/images/home-page/Better-Service-line.svg',
    imageUrlLarge: '/assets/images/home-page/Better-Service-Large.svg',
  },
  {
    title: 'Services at every step',
    subtitle: 'Everything You Need',
    body: (
      <span>
        We can help prep your home to attract more buyers, move or store your belongings, find you a
        great mortgage, or keep your new home in great shape. REX is the ONLY company that directly
        provides these services and more to ensure <b>a great experience from purchase to sale</b>.
      </span>
    ),
    imageUrl: '/assets/images/home-page/Everything-You-Need-line.svg',
    imageUrlLarge: '/assets/images/home-page/Everything-You-Need-Large.svg',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[800],
    padding: theme.spacing(3.5, 0, 5),
  },
  container: {
    padding: theme.spacing(3, 0, 0),

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8, 2, 0),
      position: 'relative',
    },
  },
  heading: {
    color: theme.palette.common.white,
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0, 2),
  },
  slide: {
    display: 'grid',
    gridTemplateAreas: `
      "title"
      "image"
      "body"
      "cta"
    `,
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'min-content',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 10),
      gridTemplateAreas: `
        "title image"
        "body image"
        "cta image"
      `,
      gridTemplateColumns: '3fr 2fr',
      gridTemplateRows: `auto`,
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateRows: `${theme.spacing(12.5)}px auto 1fr`,
      margin: theme.spacing(0, 12),
    },
  },
  servicesSlide: {
    [theme.breakpoints.up('md')]: {
      gridTemplateAreas: `
        "title image"
        "body image"
      `,
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateRows: `${theme.spacing(12.5)}px auto`,
    },
  },
  title: {
    gridArea: 'title',
    color: theme.palette.common.white,
    padding: theme.spacing(0, 2),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      alignSelf: 'end',
      marginBottom: theme.spacing(3.5),
      padding: theme.spacing(0),
      textAlign: 'left',
    },
    [theme.breakpoints.only('xs')]: {
      height: theme.spacing(9),
    },
  },
  subtitle: {
    textAlign: 'center',
    color: theme.palette.common.white,
    padding: theme.spacing(0, 2, 1),
    [theme.breakpoints.up('md')]: {
      paddingBottom: 0,
    },
  },
  text: {
    color: theme.palette.common.white,
    padding: theme.spacing(0, 2),
    textAlign: 'center',
    gridArea: 'body',
    [theme.breakpoints.up('md')]: {
      padding: 0,
      textAlign: 'left',
    },
  },
  slideCta: {
    marginLeft: theme.spacing(-1),
    marginTop: theme.spacing(2),
    gridArea: 'cta',
    alignSelf: 'start',
    [theme.breakpoints.up('md')]: {
      justifySelf: 'left',
    },
  },
  graphic: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gridArea: 'image',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
      marginLeft: theme.spacing(6),
    },
  },
  image: {
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(40),
      width: '100%',
    },
    [theme.breakpoints.only('md')]: {
      height: theme.spacing(32.5),
    },
    [theme.breakpoints.up('lg')]: {
      width: '80%',
    },
  },
  dot: {
    borderRadius: '50%',
    height: theme.spacing(0.75),
    width: theme.spacing(0.75),
  },
  navigationButtons: {
    // This is an override for Swiper's css which necessitates !important
    // This should be temporary and removed once we clean up the carousels
    '&:after': {
      fontSize: `${theme.spacing(2.5)}px !important`,
      color: `${theme.palette.common.white} !important`,
    },
  },
  ctaContainer: {
    margin: 'auto',
    display: 'flex',
    padding: theme.spacing(0, 2),
    width: '100%',
    justifyContent: 'stretch',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.spacing(70),
      padding: theme.spacing(3, 2, 2),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(2),
    },
  },
  cta: {
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[800],
  },
  carouselNavStyles: {
    padding: 0,

    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(-3),
      marginLeft: theme.spacing(10),
      placeContent: 'unset',
      position: 'absolute',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(12),
    },
  },
}));

function Differentiation() {
  const theme = useTheme();
  const isUseLargeBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <RexTypography variant="h3" className={classes.heading}>
          Why REX???
        </RexTypography>
        <Carousel
          activeNavDotColor={theme.palette.primary.main}
          navDotStyles={classes.dot}
          navStyles={classes.carouselNavStyles}
          navigation={{
            prevEl: `.swiper-button-prev.${classes.navigationButtons}`,
            nextEl: `.swiper-button-next.${classes.navigationButtons}`,
          }}
        >
          {SLIDE_DATA.map(({ title, subtitle, body, cta, imageUrl, imageUrlLarge }, index) => (
            <Box display="flex" data-testid={`slide-${index}`} key={title}>
              <div className={clsx(classes.slide, !cta && classes.servicesSlide)}>
                <RexTypography variant="subtitle1" className={classes.title}>
                  {title}
                </RexTypography>
                <div className={classes.graphic}>
                  {isUseLargeBreakpoint ? (
                    <LazyLoadImage
                      className={classes.image}
                      src={isUseLargeBreakpoint ? imageUrlLarge : imageUrl}
                      alt={subtitle}
                    />
                  ) : (
                    <LazyLoadImage
                      className={classes.image}
                      src={isUseLargeBreakpoint ? imageUrlLarge : imageUrl}
                      alt={subtitle}
                    />
                  )}
                  <RexTypography variant="h6" className={classes.subtitle}>
                    {subtitle}
                  </RexTypography>
                </div>
                <RexTypography variant="body1" className={classes.text}>
                  {body}
                </RexTypography>
                {!!cta?.url && !!cta?.copy && (
                  <Button
                    variant="text"
                    color="primary"
                    href={cta.url}
                    className={classes.slideCta}
                  >
                    {cta.copy}
                  </Button>
                )}
              </div>
            </Box>
          ))}
        </Carousel>
      </Container>
      <div className={classes.ctaContainer}>
        <RexButton fullWidth data-testid="buy-button" href="/homes" className={classes.cta}>
          {isUseLargeBreakpoint ? 'Start Buying' : "Let's Buy"}
        </RexButton>
        <RexButton
          fullWidth
          data-testid="sell-button"
          href="/listingAgreement"
          className={classes.cta}
        >
          {isUseLargeBreakpoint ? 'Start Selling' : "Let's Sell"}
        </RexButton>
      </div>
    </div>
  );
}

export default Differentiation;
