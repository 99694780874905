export default [
  {
    id: 'bloomberg',
    src: '/assets/images/home-page/bloomberg.svg',
    alt: 'Bloomberg',
  },
  {
    id: 'forbes',
    src: '/assets/images/home-page/forbes.svg',
    alt: 'Forbes',
  },
  {
    id: 'newYorkTimes',
    src: '/assets/images/home-page/new-york-times.svg',
    alt: 'The New York Times',
  },
  {
    id: 'losAngelesTimes',
    src: '/assets/images/home-page/los-angeles-times.svg',
    alt: 'Los Angeles Times',
  },
  {
    id: 'wallStreetJournal',
    src: '/assets/images/home-page/wall-street-journal.svg',
    alt: 'The Wall Street Journal',
  },
];
