import React, { useContext } from 'react';
import { PropTypes as MetricsPropTypes } from 'react-metrics';
import PropTypes from 'prop-types';
import { GlobalDataContext } from 'contexts/GlobalDataContext/GlobalDataContext';
import ENV from 'constants/env';
import { NavBar } from '@rex-change/brix';

const { REX_USER_DASHBOARD_URL } = ENV;

function Header({ homesSearchQuery }, { metrics }) {
  const onTrack = (name, category, label) => {
    metrics.track(name, { category, label });
  };

  const { phoneNumber } = useContext(GlobalDataContext);

  return (
    <NavBar
      phoneNumber={phoneNumber}
      onTrack={onTrack}
      dashboardHost={REX_USER_DASHBOARD_URL}
      data-test-id="nav-bar"
      data-testid="nav-bar"
      homesSearchQuery={homesSearchQuery}
    />
  );
}

Header.propTypes = {
  homesSearchQuery: PropTypes.string,
};

Header.defaultProps = {
  homesSearchQuery: '',
};

Header.contextTypes = {
  metrics: MetricsPropTypes.metrics,
};

export default Header;
