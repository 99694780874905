import React, { useState } from 'react';
import { RexTypography, RexButton } from '@rex-change/brix';
import { makeStyles, Tabs, Tab, useTheme, useMediaQuery } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import DashboardIcon from 'img/svg/icon-dashboard.svg';
import ENV from 'constants/env';

const { REX_USER_DASHBOARD_URL } = ENV;

const TABS = {
  NO_BARRIERS: 'NO_BARRIERS',
  SMART_TECH: 'SMART_TECH',
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3.5, 3),
  },
  title: {
    textAlign: 'center',
  },
  heroImage: {
    display: 'block',
    maxWidth: '100%',
    margin: 'auto',
  },
  tabCopy: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  ctaContainer: {
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.spacing(70),
    },
  },
  inlineCtaContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'stretch',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  appLinksContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-around',
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-between',
    },
  },
  appLink: {
    flexGrow: 1,
    '&:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.spacing(19),
    },
  },
  cta: {
    marginLeft: 0,
    marginRight: 0,
  },
  buttonIcon: {
    marginBottom: '0.05em',
  },
}));

function Spoke() {
  const classes = useStyles();
  const theme = useTheme();
  const isUseFullCtaText = useMediaQuery(theme.breakpoints.up('sm'));
  const [selectedTab, setSelectedTab] = useState(TABS.NO_BARRIERS);

  return (
    <div className={classes.root}>
      <RexTypography variant="subtitle2" className={classes.title}>
        You spoke,
      </RexTypography>
      <RexTypography variant="h5" className={classes.title}>
        We listened
      </RexTypography>
      <TabContext value={selectedTab}>
        <TabPanel value={TABS.NO_BARRIERS}>
          <img
            className={classes.heroImage}
            src="/assets/images/home-page/Spoke-Barriers.svg"
            alt=""
            data-testid="barriers-image"
          />
        </TabPanel>
        <TabPanel value={TABS.SMART_TECH}>
          <img
            className={classes.heroImage}
            src="/assets/images/home-page/Spoke-Tech.svg"
            alt=""
            data-testid="tech-image"
          />
        </TabPanel>
        <Tabs
          variant="fullWidth"
          value={selectedTab}
          onChange={(event, value) => setSelectedTab(value)}
        >
          <Tab
            value={TABS.NO_BARRIERS}
            label={<RexTypography variant="h6">No Barriers</RexTypography>}
            data-testid="barriers-tab"
          />
          <Tab
            value={TABS.SMART_TECH}
            label={<RexTypography variant="h6">Smart Tech</RexTypography>}
            data-testid="tech-tab"
          />
        </Tabs>
        <TabPanel
          className={classes.tabCopy}
          value={TABS.NO_BARRIERS}
          data-testid="barriers-content"
        >
          <RexTypography variant="body1">
            At REX, you’ve been trusting us to help buy or sell your home for years. We recognize
            that opportunity is everywhere, which is why we’ve decided to begin listing on the MLS —
            all while still bypassing traditional agent fees, saving you money.
          </RexTypography>
          <div className={classes.ctaContainer}>
            <div className={classes.inlineCtaContainer}>
              <RexButton className={classes.cta} fullWidth href="/homes">
                {isUseFullCtaText ? 'Start Buying' : "Let's Buy"}
              </RexButton>
              <RexButton className={classes.cta} fullWidth href="/listingAgreement">
                {isUseFullCtaText ? 'Start Selling' : "Let's Sell"}
              </RexButton>
            </div>
          </div>
        </TabPanel>
        <TabPanel className={classes.tabCopy} value={TABS.SMART_TECH} data-testid="tech-content">
          <RexTypography variant="body1">
            With an app supported by iOS and Android, an easy-to-use customer dashboard, AI-based
            marketing and excellent tech support, REX Homes fully integrates modern technology to
            help you buy or sell your home.
          </RexTypography>
          <div className={classes.ctaContainer}>
            <div className={classes.inlineCtaContainer}>
              <RexButton className={classes.cta} fullWidth href="/homes">
                {isUseFullCtaText ? 'Start Buying' : "Let's Buy"}
              </RexButton>
              <RexButton className={classes.cta} fullWidth href="/listingAgreement">
                {isUseFullCtaText ? 'Start Selling' : "Let's Sell"}
              </RexButton>
            </div>
            <RexButton
              className={classes.cta}
              fullWidth
              variant="text"
              endIcon={<DashboardIcon className={classes.buttonIcon} />}
              href={REX_USER_DASHBOARD_URL}
            >
              View Dashboard
            </RexButton>
          </div>
        </TabPanel>
      </TabContext>
    </div>
  );
}

export default Spoke;
